const Logo = (props) => (
  <img
    alt="Logo"
    src="/static/Logo Castle Rock.svg"
    {...props}
    height = "50px"
  />
);

export default Logo;
