import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';

import { getUsefullData,PROGRAM } from "../../utils/utils";
import {connect} from "react-redux";
import {getDates} from "../../store/actions";
import { React, useState, useEffect} from "react";
import "./ProductCard.css";

const NrPersoane = (props) => {

  return (
    <>
      <p style={{margin: "1em"}}>Persoane cu rezervare: <strong>{props.nr}</strong> </p>
      { props.details && props.details.length!==0 &&
        <div style={{backgroundColor: "#ffffff", padding: "1em", minHeight: "auto"}} className={"intervalContainer"}>
          { props.details.map( detail => (
            <p style={{backgroundColor: "#ffffff", border: "1px solid #f5f5f5"}}>{detail.name} - {detail.phone}</p>
          ))}
        </div>
      }

    </>
  )

};

const OccupiedIntervals = (props) => {
  const st = parseInt(PROGRAM.START.substring(0,2));
  const dr = parseInt(PROGRAM.FINISH.substring(0,2));

  let time = 1;
  let intervals = [];

  for(let i = st;i<dr;++i){
    let isAvailable = true;
    let name = "";
    let phone = "";

    let classes = [];

    props.fullIntervals.forEach( takenInterval => {
      let takenSt = takenInterval.checkIn; // -1 and +1 for preparation
      let takenDr = takenInterval.checkOut;
      let auxSt = parseInt(i);
      let auxDr = parseInt(i+time);

      let x = { st: takenSt, dr: takenDr};
      let y = { st: auxSt, dr: auxDr };
      if(x.st > y.st) { let aux = {...x}; x = {...y}; y = {...aux};}
      let mx = Math.max(x.st,x.dr);
      let mn = Math.min(y.st,y.dr);

      if( (isAvailable !== (mx<=mn)) && isAvailable){
        name = takenInterval.name;
        phone = takenInterval.phone;
      }

      isAvailable =  isAvailable && (mx <= mn);
    });

    isAvailable ? classes.push(" free"): classes.push(" full");

    //let objToComp1 = {st: checkInHour, dr: checkOutHour};
    //let objToComp2 = {st: i, dr: i+1};
    //console.log(objToComp1, objToComp2, isBetween(objToComp1,objToComp2));
    //if(isBetween(objToComp2, objToComp1) && !isSelected) classes.push(" between-selected");

    intervals.push({
      st: i,
      dr: i+time,
      text: i+":00 - "+(i+time)+":00",
      isAvailable: isAvailable,
      classNames: classes,
      name: name,
      phone: phone
    });
  }

  return(
    <ul className={"intervalContainer"}>
      {intervals.map( interval => {
        let styles = {
          display: "",
          //justifyContent: interval.isAvailable ? "center" : "space-around",
          justifyContent: "center",
          alignItems: "center"
        };

        return (
          <>
            <li
              key = {interval.text}
              className={[...interval.classNames]}
              style={styles}
            >
              <p>{ interval.text }</p>
              { interval.name.length && interval.phone.length ?
                  <p>{ interval.name } - {interval.phone}</p> : ""
              }
            </li>
          </>
        )
      })}
    </ul>
  )

};

const ProductCard = (props) => {

  const [nr, setNr] = useState(0);
  const [occupiedIntervals, setOccupiedIntervals] = useState([]);
  const [personalData, setPersonalData] = useState([]);

  useEffect( () => {
    props.getDatesProp();
  },[]);

  useEffect( () => {
    if(props.datesProp && props.datesProp[props.type]) {
      let test = getUsefullData(props.type, props.datesProp[props.type], props.date);
      //console.log(test, props);
      try{
        setNr(test.nr);
        setOccupiedIntervals(test.occupiedIntervals);
        setPersonalData(test.personalData);
      } catch (e) {
        console.log("EROARE", props.type, props.date, test);
      };


    } else {
      console.log("eroare", props);
    }
  },[props]);

  return <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}
  >
    <CardContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',

        }}
      >
      </Box>
      <Typography
        align="center"
        color="textPrimary"
        gutterBottom
        variant="h4"
        style={{textTransform: "capitalize"}}
      >
        {props.type}
      </Typography>
      <Typography
        align="center"
        color="textPrimary"
        variant="body1"
      >
        { (props.type === "cazare" || props.type === "piscina" ) &&
          <NrPersoane nr={nr} details = {personalData}/>}
        { (props.type === "ATV" || props.type === "UTV" || props.type === "Paintball") &&
          <OccupiedIntervals fullIntervals = {occupiedIntervals} />}
      </Typography>
    </CardContent>
    <Box sx={{flexGrow: 1}}/>
  </Card>
};

ProductCard.propTypes = {

};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDatesProp: () => dispatch(getDates()),
  }
};

const mapStateToProps = (state) => {
  return {
    datesProp: state.dates,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductCard);
