//export const SERVER_ADDRESS = "http://localhost:3000"; //dev purposes only
export const SERVER_ADDRESS = "https://castlerock-server.tech";
export const TVA = 0.06;

export const JOURNEY_CAPACITY = {
  ATV: 6,
  UTV: 2,
};

export const PISCINA_CAPACITY = 1;

export const PROGRAM = {
  START: "10:00",
  FINISH: "20:00"
};

export const JOURNEY_PRICE = {
  ATV: [250,650,1250],
  UTV: [350,750,1350]
};

export const JOURNEY_DURATION = {
  ATV: [1,3,6],
  UTV: [1,3,6]
};

export const PISCINA_PRICE = 70;

export const CAZARE_PRICE = 120;
export const PAINTBALL_PRICE = {
  0: 65,
  1: 90,
  2: 112,
  3: 112,
};
export const PAINTBALL_DURATION = {
  0: 1,
  1: 1,
  2: 3,
  3: 3
};
export const PAINTBALL_CAPACITY = 1;

export const getNumberOfDays = (passedDate1, passedDate2) => {

  let date1 = passedDate1.substring(0,10);
  let date2 = passedDate2.substring(0,10);

  let d1 = new Date(date1);
  let d2 = new Date(date2);

  let difTime = d2.getTime() - d1.getTime();
  let difDays = (difTime - difTime % (1000 * 3600 * 24)) / (1000 * 3600 * 24) - difTime % (1000 * 3600 * 24) ;
  return difDays;
};

export const compareDates = (date1, date2, test) => { // returns true if the first is greater ( later )
  try{
    if(!date1 || !date2 || date1.length < 8 || date2.length < 8) {
      return null;
    }
    let parts1 =date1.split('/');
    let parts2 = date2.split('/');

    for(let i=0;i<3;++i) {
      parts1[i] = parseInt(parts1[i]);
      parts2[i] = parseInt(parts2[i]);
    }

    if(parts1[2] === parts2[2]) {
      if(parts1[0] === parts2[0]){
        return parts1[1] > parts2[1];
      }
      return parts1[0] > parts2[0];
    }
    return parts1[2] > parts2[2];
  } catch(error) {
    console.log(error);
  }

};

export const convertToLocalString = (date) => {
  date = date.format('l');
  let parts = date.split('.');
  return parts[1] + '/' + parts[0] + '/' + parts[2];
};

export const formValidator = (identifier, value) => {

  let isValid = true;
  let errMsg = '';

  switch (identifier) {

    case('firstName'):
    case('secondName'):
      if (value.trim() === '' && isValid) {
        isValid = false;
        errMsg = 'Numele trebuie completat obligatoriu';
      } else if (!value.match(/^[a-zA-Z]*$/) && isValid) {
        isValid = false;
        errMsg = 'Numele trebuie să fie compus doar din litere';
      } else if (value.length < 2 && isValid) {
        isValid = false;
        errMsg = 'Numele trebuie să abiă cel puțin 2 caractere';
      }
      break;

    case('email') :
      if (value.trim() === '' && isValid) {
        isValid = false;
        errMsg = 'Adresa de email trebuie completat obligatoriu';
      } else {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(value)) {
          isValid = false;
          errMsg = "Te rog introdu o adresă de email validă"
        }
      }
      break;
    case('phone') :
      if ( value.trim() === '' && isValid) {
        isValid = false;
        errMsg = 'Te rugăm introdu un număr de telefon real';
      } else if(value.length !== 10 && isValid) {
        isValid = false;
        errMsg = 'Te rugăm introdu un număr de 10 cifre ( fără prefix de țară )'
      }
    default:

  }

  return {isValid: isValid, errorsMsg: errMsg};
}

export const equalDates = (date1, date2) => {
  if(!date1 || !date2) {
    return null;
  }
  let parts1 =date1.split('/');
  let parts2 = date2.split('/');

  let areEqual = true;
  for(let i=0;i<3;++i) {
    let d1 = parseInt(parts1[i]);
    let d2 = parseInt(parts2[i]);
    areEqual = areEqual && ( d1 === d2);
  }
  return areEqual;
};

export const getHours = (date) => {
  let st = date.checkIn.split("T")[1].substring(0,2);
  let dr = date.checkOut.split("T")[1].substring(0,2);
  return parseInt(dr)-parseInt(st);
};

export const getUsefullData = (key, dtb, date) => { ///dtb = numai colectia key-ului

  if(!dtb.length) return;

  let occupiedIntervals = [];
  let nr = 0;
  let personalData = [];

  //correct date
  let parts = date.split("-");
  date = parts[1]+"/"+parts[2]+"/"+parts[0];

  dtb.forEach(entry => {
    if(equalDates(entry.checkIn, date)){
      if(key === "cazare") { // cate grupuri vin in ziua respectiva
        nr ++;
        personalData.push({
          phone: entry.phone,
          name: entry.firstName + " " + entry.secondName
        });
      } else if(key === "piscina") {
        nr += entry.persons
      } else {
        occupiedIntervals.push({
          checkIn: parseInt(entry.checkIn.split("T")[1].substring(0,2)),
          checkOut: parseInt(entry.checkOut.split("T")[1].substring(0,2)),
          phone: entry.phone,
          name: entry.firstName
        })
      }
    }
  });
  return {
    occupiedIntervals: occupiedIntervals,
    nr: nr,
    personalData: personalData
  }
};

export const convertStringToLocalDate = (string) => {
  let bigParts = string.split('T');
  let date =  bigParts[0];
  let parts = date.split('/');
  let time = bigParts[1] ? bigParts[1] : "";
  return ( parts[1] + '/' + parts[0] + '/' + parts[2] + " " + time);
};