import * as actionTypes from "../actions/actionTypes";
/*import Data from "../../static/dataNEW";*/

const initialState = {
  fullDates: {
    "cazare": [],
    "ATV": [],
    "UTV": [],
    "piscina": [],
    "paintball": []
  },
  dates: {
    "cazare": [],
    "ATV": [],
    "UTV": [],
    "piscina": [],
    "paintball": [],
  },
  services: [],
  status: {},
  isLoading: false,
  isLoggedIn: false,
}; //not defined yet

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FULL_DATES:
      return {
        ...state,
        fullDates: action.fullDates
      };
    case actionTypes.GET_DATES:
      if(action.dates)
        return {
          ...state,
          dates: action.dates
        };
      return {
        ...state
      };
    case actionTypes.ADD_PRODUCT_TO_CART:
      let newArray = [...state.services];
      newArray.push(action.service);
      return {
        ...state,
        services: newArray
      };
    case actionTypes.CONFIRM_ORDER:
      return {
        ...state,
      };
    case actionTypes.GET_STATUS:
      return {
        ...state,
        status: action.status
      };
    case actionTypes.CHANGE_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading
      };
    case actionTypes.LOGIN:
      return {
        ...state,
        isLoggedIn: action.succes
      };
    default:
      return {
        ...state
      }
  }
};

export default appReducer;
