import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Pagination
} from '@material-ui/core';
import ProductListToolbar from 'src/components/product/ProductListToolbar';
import ProductCard from 'src/components/product/ProductCard';
import products from 'src/__mocks__/products';

import { useState } from "react";

const Schedule = () => {

  const types = ["cazare","piscina","ATV","UTV","Paintball"];
  const [date,setDate] = useState("");

  const handleDateChange = (date) => {

    setDate(date);
  };

  return (
    <>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <ProductListToolbar handleDateChange = {handleDateChange}/>
        <Box sx={{pt: 3}}>
          <Grid
            container
            spacing={3}
          >
            {types.map((type) => (
              <Grid
                item
                key={type}
                lg={4 + 2*(type === "cazare" || type === "piscina")}
                md={6}
                xs={12}
              >
                <ProductCard type = {type} date ={date}/>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  </>
  );
};

export default Schedule;
