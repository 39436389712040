import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import { roRO } from "@material-ui/core/locale";

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#6B8E4E'
    },
    text: {
      primary: '#1B2726',
      secondary: '#3C5148'
    }
  },
  shadows,
  typography,
},roRO);

export default theme;
