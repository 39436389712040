import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import PoolIcon from '@material-ui/icons/Pool';
import HouseIcon from '@material-ui/icons/House';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import FlagIcon from '@material-ui/icons/Flag';

import { red } from '@material-ui/core/colors';
import { useState, useEffect } from "react";
import { getHours } from "../../utils/utils";

const Service = (props) => {

  const [valueToDisplay, setValueToDisplay ] = useState("");
  const icons = {
    "ATV": <DirectionsBikeIcon />,
    "UTV": <DirectionsBikeIcon />,
    "Paintball": <FlagIcon />,
    "piscina": <PoolIcon />,
    "cazare": <HouseIcon />
  };

  useEffect( () => {

    if(!props.dates) return;
    if(props.type === "cazare") {
      setValueToDisplay(props.dates.length + " închirieri");
    } else if(props.type === "ATV" || props.type === "UTV"){
      let hours = 0;
      props.dates.map( date => hours += getHours(date));
      setValueToDisplay(hours + " ore");
    } else {
      let persons = 0;
      props.dates.map( date => persons += parseInt(date.persons));
      setValueToDisplay(persons + " persoane");
    }
  },[props]);

  let possibleColors = ["var(--var-primary)", "var(--var-secondary)", "var(--var-third)"];

  return <Card
    sx={{height: '100%'}}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{justifyContent: 'space-between'}}
      >
        <Grid item style={{maxWidth: "50%"}}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            {props.title}
          </Typography>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            { valueToDisplay }
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: possibleColors[Math.floor((Math.random() * 3))],
              height: "3rem",
              width: "3rem"
            }}
          >
            { icons[props.type]}
          </Avatar>
        </Grid>
      </Grid>
      {/*<Box
        sx={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <ArrowDownwardIcon sx={{color: red[900]}}/>
        <Typography
          sx={{
            color: red[900],
            mr: 1
          }}
          variant="body2"
        >
          12%
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
        >
          Since last month
        </Typography>
      </Box>*/}
    </CardContent>
  </Card>
};

export default Service;
