import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import Service from 'src/components/dashboard/Service';
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestProducts from 'src/components/dashboard//LatestProducts';
import Sales from 'src/components/dashboard//Sales';
import TasksProgress from 'src/components/dashboard//TasksProgress';
import TotalCustomers from 'src/components/dashboard//TotalCustomers';
import TotalProfit from 'src/components/dashboard//TotalProfit';
import TrafficByDevice from 'src/components/dashboard//TrafficByDevice';

import {useState, useEffect } from "react";
import {connect} from "react-redux";
import { getDates } from "../store/actions";

const Dashboard = (props) => {
  useEffect(() => {
    props.getDatesProp();
  }, []);

  let services = [
    {title: "ATV", dates: props.datesProp["ATV"], type: "ATV"},
    {title: "Piscina", dates: props.datesProp["piscina"], type: "piscina"},
    {title: "UTV", dates: props.datesProp["UTV"],type: "UTV"},
    {title: "Paintball", dates: props.datesProp["Paintball"], type: "Paintball"},
    {title: "Cazare", dates: props.datesProp["cazare"], type: "cazare"}
  ];

  return <>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          { services.map( service => {
              return <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                key = {service.type}
              >
                <Service {...service}/>
              </Grid>
          })}

          {/*<Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalCustomers dates={props.datesProp}/>
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TasksProgress dates={props.datesProp}/>
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalProfit sx={{height: '100%'}} dates={props.datesProp}/>
          </Grid>*/}
          {/*          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <Sales />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficByDevice sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestOrders />
          </Grid>*/}
        </Grid>
      </Container>
    </Box>
  </>
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDatesProp: () => dispatch(getDates()),
  }
};

const mapStateToProps = (state) => {
  return {
    datesProp: state.dates,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
