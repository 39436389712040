import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import moment from "moment";
import "moment/locale/ro";
import {useState, useEffect} from "react";

const ProductListToolbar = (props) => {

  const [date, setDate] = useState("");

  useEffect( () => {
    moment.updateLocale('ro');

    let today = moment().format("YYYY-MM-DD");
    setDate(today);
    props.handleDateChange(today);
  },[]);

  const onDateChange = (e) => {
    e.preventDefault();
    let newDate = e.target.value;
    props.handleDateChange(newDate);
    setDate(newDate);
  };

  return <Box {...props}>
    <Box sx={{mt: 3}}>
      <Card>
        <CardContent>
          <Box sx={{maxWidth: 500}}>
            <TextField
              fullWidth
              type={"date"}
              value={date}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon/>
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search product"
              variant="outlined"
              onChange={ (e) => onDateChange(e) }
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
};

export default ProductListToolbar;
