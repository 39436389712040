import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { convertStringToLocalDate } from "../../utils/utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TokenDialog(props) {

  const [open, setOpen] = useState(false);
  let text = "Se verifică...";
  if(props.isLoading) {
    text = "Se verifică...";
  } else if(props.status.success) {
    let data = props.status.success;
    let dataToShow = {
      Nume: data.firstName + " " + data.secondName,
      Email: data.email,
      Serviciu: data.type,
      Perioada: convertStringToLocalDate(data.checkIn) + " - " + convertStringToLocalDate(data.checkOut),
      "Nr persoane": data.persons,
      Pachet: data.pachet,
      Telefon: data.phone,
      "Numar factura": data.invoice_id,
    };
    text =
      <div style={{lineHeight: "1.68421"}}>

        <p>Înregistrarea a fost confirmată cu succes!</p>
        <p style={{paddingBottom: "1rem"}}>Datele înregistrării:</p>
        { Object.keys(dataToShow).map( key => {
          if(dataToShow[key])
            return <p> {key}: {dataToShow[key]}</p>
        })}
      </div>;
  } else if(props.status.used) {
    text = <p>Înregistrarea a fost deja activată!</p>;
  } else if(!props.status.isValid) {
    text = <p>Înregistrarea nu este validă!</p>
  }

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Verificare cod înregistrare</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          { text }
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}