import React from "react";
import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio as RadioMUI,
  withStyles, Typography,
  Checkbox,
} from '@material-ui/core';

import * as UTILS from "../../utils/utils";
import { confirmOrder } from "../../store/actions";
import moment from "moment";

const Radio = withStyles({
  root: {
    color: "var(--var-third)",
    '&$checked': {
      color: "var(--var-third)",
    },
  },
  checked: {},
})((props) => <RadioMUI color="default" {...props} />);

let texts = {
  'ATV': [
    {text: "Pachet 1 - 1h - 250 lei"},
    {text: "Pachet 2 - 3h - 650 lei"},
    {text: "Pachet 3 - 6h - 1250 lei"},
  ],
  'UTV': [
    {text: "Pachet 1 - 1h - 350 lei"},
    {text: "Pachet 2 - 3h - 650 lei"},
    {text: "Pachet 3 - 6h - 1250 lei"},
  ],
  'Paintball': [
    {text: "Pachet 1 - 200 bile + echipament"},
    {text: "Pachet 2 - 300 bile + echipament"},
    {text: "Pachet 3 - aniversar copii"},
    {text: "Pachet 4 - aniversar adulti"},
  ]
};

const SettingsPassword = (props) => {

  const [values, setValues] = useState({
    firstName: '',
    secondName: '',
    email: '',
    phone: '',
    type: '',
    checkIn: '',
    checkOut: '',
    persons: 0,
    pachet: '',
    extraOptiune: "",
    used: false,
    no_bill: false,
  });
  const [totalPrice, setTotalPrice] = useState(0);

  const handleChange = (event) => {

    let key = event.target.name;
    let value = event.target.value;
    if(key === "persons") value = value > 1 ? value: 1;
    if(key === "no_bill") {
      setValues({
        ...values,
        no_bill: !values.no_bill
      });
    } else {
      setValues({
        ...values,
        [key]: value
      });
    }
  };

  useEffect( () => {
    if(values.type === "cazare"){ //merge
      let nr = values.persons;
      let checkIn = values.checkIn;
      let checkOut = values.checkOut;
      if(!nr || !checkIn || !checkOut) return;
      let totalPrice = nr*(UTILS.getNumberOfDays(checkIn, checkOut))*UTILS.CAZARE_PRICE;
      setTotalPrice(totalPrice);
    }
    if(values.type === "ATV" || values.type === "UTV") {
      if(!values.pachet) return;
      let totalPrice = UTILS.JOURNEY_PRICE[values.type][values.pachet];
      setTotalPrice(totalPrice);
    }
    if(values.type === "Paintball") {
      let nr = values.persons;
      let pack = values.pachet;
      if(!nr || !pack) return;
      let totalPrice = nr * UTILS.PAINTBALL_PRICE[pack];
      setTotalPrice(totalPrice);
    }
    if(values.type === "piscina"){
      let nr = values.persons;
      if(!nr) return;
      let totalPrice = nr*UTILS.PISCINA_PRICE;
      setTotalPrice(totalPrice);
    }
  }, [values]);

  const handleDataSubmit = () => {

    let checkIn = moment(values.checkIn).format("MM/DD/YYYYTHH:mm");
    let checkOut = moment(values.checkOut).format("MM/DD/YYYYTHH:mm");

    if(values.type === "cazare" || values.type === "piscina") {
      checkIn = moment(values.checkIn).format("MM/DD/YYYY");
      checkOut = moment(values.checkOut).format("MM/DD/YYYY");
    }
    let objectToAdd = {
      amount: totalPrice,
      order_desc: "Adaugare realizata",
      customerInfo: {
        email: values.email,
        firstName: values.firstName,
        secondName: values.secondName,
        phone: values.phone,
      },
      produse: [{
        checkIn: checkIn,
        checkOut: checkOut,
        extraOptiune: "",
        pachet: values.pachet,
        persons: values.persons,
        totalPrice: totalPrice,
        type: values.type
      }],
      status_plata: "finalizat",
      no_bill: !values.no_bill,
    };
    props.confirmOrderProp(objectToAdd);
  };

  return (
    <form {...props}>
      <Card>
        <CardHeader
          subheader="Completați câmpurile pentru a înregistra pe cine doriți manual"
          title="Adaugă înregistrare manual"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Nume"
            margin="normal"
            name="firstName"
            onChange={handleChange}
            type="text"
            value={values.firstName}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label="Prenume"
            margin="normal"
            name="secondName"
            onChange={handleChange}
            type="text"
            value={values.secondName}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            name="email"
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label="Nr. telefon"
            margin="normal"
            name="phone"
            onChange={handleChange}
            type="tel"
            value={values.phone}
            variant="outlined"
            required
          />
          <RadioGroup aria-label="Serviciu" name="type" value={values.type} onChange={handleChange} required>
            <FormControlLabel value="cazare" control={<Radio />} label="Cazare" />
            <FormControlLabel value="piscina" control={<Radio />} label="Piscina" />
            <FormControlLabel value="ATV" control={<Radio />} label="ATV" />
            <FormControlLabel value="UTV" control={<Radio />} label="UTV" />
            <FormControlLabel value="Paintball" control={<Radio />} label="Paintball" />
          </RadioGroup>
          <Typography
            color="textPrimary"
            variant="h6"
            pt={3}
          >
            Check-in
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            name="checkIn"
            onChange={handleChange}
            type="datetime-local"
            value={values.checkIn}
            variant="outlined"
            required
          />
          <Typography
            color="textPrimary"
            variant="h6"
            pt={3}
          >
            Check-out ( a nu se completa dacă nu este cazul )
          </Typography>
          <TextField
            fullWidth
            label=""
            margin="normal"
            name="checkOut"
            onChange={handleChange}
            type="datetime-local"
            value={values.checkOut}
            variant="outlined"
          />
          { !values.type || values.type === 'cazare' || values.type === 'piscina' ? "" :
            <RadioGroup aria-label="Pachet" name="pachet" value={values.pachet} onChange={handleChange}>
              { texts[values.type].map( ({text, value},index) => {
                return <FormControlLabel value={index.toString()} control={<Radio />} label={text} />
              })}
            </RadioGroup>
          }
          <TextField
            fullWidth
            label="Câte persoane?"
            margin="normal"
            name="persons"
            onChange={handleChange}
            type="number"
            value={values.persons}
            variant="outlined"
            required
          />
          <div style={{display: "flex", alignItems: "center"}}>
            <Typography
              color="textPrimary"
              variant="h6"
              pt={0}
            >
              Generez factură?
            </Typography>
            <Checkbox checked={values.no_bill}
                      value = {values.no_bill}
                      name = "no_bill"
                      label = "Generezi factura? "
                      color = "primary"
                      onChange={handleChange}
            />
          </div>

          <Typography
            color="textPrimary"
            variant="h4"
            pt={3}
          >
            TOTAL: {totalPrice} RON
          </Typography>

        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick = {handleDataSubmit}
          >
            Adauga
          </Button>
        </Box>
      </Card>
    </form>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    confirmOrderProp: (order) => dispatch(confirmOrder(order)),
  }
};

export default connect(
  null,
  mapDispatchToProps,
)(SettingsPassword);
