import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog(props) {

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => props.handleCloseDialog(0)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Sigur vreți să ștergeți înregistrarea?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Ștergerea unei înregistrări este ireversibilă.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleCloseDialog(0)} color="primary">
          Nu
        </Button>
        <Button onClick={() => props.handleCloseDialog(1)} color="primary">
          Da
        </Button>
      </DialogActions>
    </Dialog>
  );
}