import * as actionTypes from "./actionTypes";
import * as utils from "../../utils/utils";
import axios from "axios";

export const getFullDates = () => {
  const request = axios.get(utils.SERVER_ADDRESS + '/fullDates');
  return dispatch => {
    request.then( ({data}) => {
      data = data.sort( (first, second) => {
        return utils.compareDates(first.timestamp, second.timestamp);
      });
      dispatch({
        type: actionTypes.GET_FULL_DATES,
        fullDates: data
      });
    })
      .catch( (e) => alert("Eroare la comunicarea cu serverul!"))
  }
};

export const getDates = () => {
  const request = axios.get(utils.SERVER_ADDRESS + '/dates');

  return dispatch => {
    request.then( ({data}) => {

      dispatch({
        type: actionTypes.GET_DATES,
        dates: data
      })
    }).catch( (e) => alert("Eroare la comunicarea cu serverul!"))
  }
}

export const addServiceToCart = (service) => {
  return {
    type: actionTypes.ADD_PRODUCT_TO_CART,
    service: service
  }
};

export const confirmOrder = (order) => {
  const request = axios.post(utils.SERVER_ADDRESS + '/plata', order);

  return dispatch => {
    request
      .then ( ({data}) => {
        alert("Adăugarea a fost realizată cu succes!");
        dispatch({
          type: actionTypes.CONFIRM_ORDER,
          fullDates: data
        });
      })
      .catch( (e) => alert("Eroare la comunicarea cu serverul!"))
  }
};

export const deleteEntry = (id, type) => {
  const request = axios.delete(utils.SERVER_ADDRESS + '/delete/' + id + '/' + type);
  return dispatch => {
    request
      .then( ({data}) => {
        alert("Inregistrarea a fost stearsa cu succes!");
    }).catch( (e) => alert("Eroare la comunicarea cu serverul!"));
  }
};

export const getStatus = (id) => {
  const request = axios.get(utils.SERVER_ADDRESS + '/use-token/' + id);

  return dispatch => {
    dispatch(changeLoading());
    request
      .then( ({data}) => {

        dispatch({
          type: actionTypes.GET_STATUS,
          status: data
        });
        dispatch(changeLoading());
      })
      .catch( (e) => alert("Eroare la comunicarea cu serverul!"))
  }
};

export const changeLoading = () => {
  return dispatch => dispatch ({
    type: actionTypes.CHANGE_LOADING,
  });
};

export const login = (password) => {
  const request = axios.post(utils.SERVER_ADDRESS + '/login', {password:password});
  console.log("IN LOGIN " , password);

  return dispatch => {
    dispatch(changeLoading());
    request
      .then( ({data}) => {
        dispatch({
          type: actionTypes.LOGIN,
          ...data
        });
        dispatch(changeLoading());
      })
      .catch( (e) => alert("Eroare la comunicarea cu serverul!"))
  }
};
