import React from "react";

import {Navigate, useParams} from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import Schedule from 'src/pages/Schedule';
import Register from 'src/pages/Register';
import Settings from 'src/pages/AddEntry';
import {SERVER_ADDRESS} from "./utils/utils";
import CheckEntry from "./pages/CheckEntry";

const CheckEntryComponent = () => {
  let { id } = useParams();
  return <CheckEntry id = {id}/>
};

const Factura = () => {
  let { id } = useParams();
  window.location.href = SERVER_ADDRESS + '/billings/' + id + '.pdf';
  return null;
};

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'check-entry/:id', element: <CheckEntryComponent />},
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <Schedule /> },
      { path: 'add-entry', element: <Settings /> },
      { path: 'factura/:id', element: <Factura />},
      { path: '*', element: <Navigate to="/app/dashboard" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  }
];

export default routes;
