import React, {useEffect} from "react";
import { connect } from "react-redux";
import { getStatus } from "../store/actions";

import TokenDialog from "../components/check-entry/TokenDialog";

const CheckEntry = (props) => {

  useEffect( () => {
    props.getStatusProp(props.id);

  },[]);

  return (<TokenDialog {...props}></TokenDialog>);

};

const mapStateToProps = (state) => {
  return {
    status: state.status,
    isLoading: state.isLoading,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getStatusProp: (id) => dispatch(getStatus(id))
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckEntry);
