import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import { login } from "../store/actions/index";
import { connect } from "react-redux";
import {useEffect, useState} from "react";

const Login = (props) => {
  const navigate = useNavigate();

  const [error, setError] = useState("");

  useEffect( () => {
    if(props.isLoggedIn){
      navigate('/app/dashboard',{ replace: true });
    } //todo: display if error
  },[props.isLoggedIn]);
  let submit = (password) => {
    event.preventDefault();
    props.loginProps(password);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required('Parola este necesară')
            })}
            onSubmit={() => {
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={ () => submit(values.password)}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Loghează-te
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pb: 1,
                    pt: 3
                  }}
                >
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Parola"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Typography
                  color="red"
                  variant="h5"
                >
                  { error }
                </Typography>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
/*                    disabled={isSubmitting}*/
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Loghează-te
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.isLoggedIn,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginProps: (password) => dispatch(login(password))
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
