import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import {getDates, deleteEntry} from "../../store/actions";
import {connect} from "react-redux";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DescriptionIcon from '@material-ui/icons/Description';
import {Link} from "react-router-dom";
import {SERVER_ADDRESS} from "../../utils/utils";

import ConfirmationDialog from "./ConfirmationDialog";

const keys = ["#","Nume", "Telefon", "Serviciu", "Check In/Out", "Suma","Factura","Sterge"];

const CustomerListResults = (props) => {
  console.log("DATES ",props.datesProp);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState({
    key: "",
    type: ""
  });

  const [dates,setDates] = useState([]);

  const handleLimitChange = (event) => {

    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {

    setPage(newPage);
  };

  const handleDeleteEntry = (key, type) => {
    setSelectedEntry({
      key: key,
      type: type
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = (aggree) => {
    setOpenDialog(false);
    if(aggree) {
      props.deleteEntryProp(selectedEntry);
      setSelectedEntry({});
    }
  };

  useEffect( () => {
    props.getDatesProp();
  },[]);

  useEffect( () => {
    let aux = [];
    Object.keys(props.datesProp).forEach( key => aux = aux.concat([...props.datesProp[key]]));
    aux.sort( (first,second) => {
      if(first.timestamp < second.timestamp) return -1;
      if(first.timestamp  > second.timestamp ) return 1;
      return 0;
    });
    setDates(aux);

  },[props]);
  return (

    <>
      <Card {...props}>
        <TablePagination
          component="div"
          count={dates.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                { keys.map(key => {
                  return <TableCell style={{textTransform: "capitalize"}}>
                    { key }
                  </TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                dates && dates.slice(limit*(page),limit*(page+1)).map((customer) => (
                <TableRow
                  hover
                  key={customer.id}
                  selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      {customer.invoice_id === 'null' ? "Fără factură" : customer.invoice_id}
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      {customer.firstName + " " + customer.secondName}
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {customer.phone}
                  </TableCell>
                  <TableCell>
                    {customer.type}
                  </TableCell>
                  <TableCell>
                    {moment(customer.checkIn.split("T")[0]).format('DD/MM/YYYY') +
                    ( customer.checkIn.split("T")[1] ? " " + customer.checkIn.split("T")[1] : "")  +
                    " - " +
                    moment(customer.checkOut.split("T")[0]).format('DD/MM/YYYY') +
                    ( customer.checkOut.split("T")[1] ? " " + customer.checkOut.split("T")[1]: "" )}
                  </TableCell>
                  <TableCell>
                    {customer.totalPrice + " RON"}
                  </TableCell>

                  <TableCell>
                    {customer.invoice_id === -1 || customer.invoice_id === 'null' ? "" :
                      <a href={SERVER_ADDRESS + "/billings/" + customer.invoice_id + '.pdf'}
                         target="_blank"
                         style={{color: "black"}}>
                        <DescriptionIcon/>
                      </a>
                    }
                  </TableCell>
                  <TableCell
                    style={{cursor: "pointer"}}
                    onClick = { () => handleDeleteEntry(customer.id, customer.type)}>
                    <DeleteForeverIcon style={{color: "red"}}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

    </Card>
      <ConfirmationDialog
        open = {openDialog}
        handleCloseDialog = {handleCloseDialog}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDatesProp: () => dispatch(getDates()),
    deleteEntryProp: ({key, type}) => dispatch(deleteEntry(key, type)),
  }
};

const mapStateToProps = (state) => {
  return {
    datesProp: state.dates,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerListResults);

